.taskLayout {
    width: calc(390px - 26px - 12px);
    /* margin: 0 12px; */
    margin: 0 auto;
    padding: 8px 13px;
    height: 288px;
    border-radius: 12px;
    position: relative;
    z-index: 10;
    margin-top: 4px;
    background: linear-gradient(180deg, rgba(250, 250, 252, 0.80) 0.1%, #FAFAFC 41.16%, #FAFAFC 99.9%);
    margin-bottom: 20px;
}

.taskTitle {
    color: #323444;
    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.taskList {
    margin-top: 17px;
}

/* 
.taskItem {
    margin-bottom: 17px;
} */

.taskInfo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin-bottom: 17px;
}

.taskName {
    color: #323444;
    font-family: "PingFang SC";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.taskIcon {
    position: absolute;
    right: 0;
}

.icon {
    width: 17px;
    height: 17px;
}

.taskGoldAddNum {
    color: #323444;
    font-family: Ranga;
    font-size: 12px;
    font-weight: 400;
    position: relative;
    top: -1px;
}

.taskLine {
    width: 100%;
    height: 1px;
    background-color: #DBDFE7;
    margin-bottom: 17px;
}