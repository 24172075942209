.scene {
    position: absolute;
    top: 0;
    left: 0;
    width: 390px;
    height: 100%;
    z-index: 100;
}

.room {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
}

.loading {
    display: none;
}