.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 390px;
    height: 844px;
    /* right: 0;
    bottom: 0; */
    z-index: 1000;
    transition: all .2s ease;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadingJpg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.loadingGif {
    width: 90px;
    height: 90px;
    position: relative;
    z-index: 2;
}

.close {
    opacity: 0;
}