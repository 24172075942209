.uiLayout {
    position: relative;
    width: 100%;
    margin-top: 530px;
    /* left: 0; */
    z-index: 500;
    /* margin-bottom: 500px; */
}

.controls {
    position: absolute;
    top: -80px;
    left: 12px;
    display: flex;
    align-items: center;
}

.controlItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
}

.controlIcon {
    padding: 10px;
    width: 30px;
    height: 30px;
    border: 2px solid white;
    border-radius: 1000px;
}

.controlTitle {
    color: #323444;
    font-family: "PingFang SC";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 2px 9px;
    border-radius: 1000px;
}

.openTask .controlIcon {
    background: #CAEFFF;
    padding: 3px;
    width: 42px;
    height: 42px;
}

.openCode .controlIcon {
    background: #FFE3D2;
    padding: 3px;
    width: 42px;
    height: 42px;
}

.getGold .controlIcon {
    background: #FFE8A6;
}

.openTask .controlTitle {
    position: relative;
    top: -6px;
    padding-top: 4px;
    background-color: white;
}

.openCode .controlTitle {
    position: relative;
    top: -6px;
    padding-top: 4px;
    background-color: white;
}

.getGold .controlTitle {
    color: white;
}

.getGold {
    position: absolute;
    top: -120px;
    left: 80px;
}