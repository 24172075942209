.title {
    width: 200px;
    padding-top: 18px;
    padding-left: 18px;
    margin-bottom: 8px;
    color: #323444;
    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.taskList {}

.taskItem {
    width: calc(100% - 24px);
    margin: 0 auto;
    height: 157px;
    border-radius: 0%;
    border-radius: 12px;
    margin-bottom: 8px;
    position: relative;
    overflow: hidden;
}

.bg {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.infoPanel {
    height: 36px;
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.taskName {
    color: #323444;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 14px;
}

.taskInfo {
    margin-left: 28px;
    font-size: 12px;
}

.gift {
    margin-left: 46px;
    font-size: 12px;
}

.icon {
    width: 8px;
    height: 8px;
    margin-left: 2px;
}

.btn {
    width: 65px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(322deg, #02D8F3 22.91%, #0DB3FF 79.17%);
    border-radius: 1000px;
    box-shadow: 0px 8px 24px 0px rgba(12, 183, 255, 0.24);
    color: #FFF;
    font-family: "PingFang SC";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    right: 12px;
}