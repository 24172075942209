.panelLayout {
    width: calc(390px - 24px);
    padding: 0 12px;
    position: relative;
    z-index: 10;
}

.panelLayoutContent {
    position: absolute;
    top: 0;
    left: 12px;
    right: 12px;
    z-index: 1;
    color: #323444;
    padding: 12px;
}

.panelImg {
    width: 100%;
}

.panelBg {
    width: 340px;
    height: 90px;
    background: linear-gradient(322deg, #02D8F3 22.91%, #0DB3FF 79.17%);
    position: absolute;
    left: 24px;
    top: 7px;
    border-radius: 12px;
}

.panelBgBtn {
    position: absolute;
    pointer-events: all;
    float: right;
    top: 14px;
    right: 39px;
    z-index: 61;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.panelBgBtnIcon {
    width: 12px;
    margin-right: 3px;
}

.panelLayoutContentTitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.panelLayoutContentHead {
    width: 37px;
    height: 37px;
    border-radius: 10000px;
    margin-right: 11px;
    border: 2px solid white;
}

.panelLayoutContentGoldInfo {
    margin-top: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.goldNum {
    color: #323444;
    font-family: "PingFang SC";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.goldInfo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    right: 20px;
}

.goldIcon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

.num {
    color: #323444;
    font-family: "PingFang SC";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.arrow {
    width: 9px;
    height: 9px;
}

.line {
    width: 100%;
    height: 1px;
    background: #DBDFE7;
    opacity: 0.4;
    margin-top: 15px;
}

.record {
    text-align: right;
    color: #B2B2B2;
    font-family: "PingFang SC";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 7px;
}