.signin {
    width: calc(390px - 24px);
    margin: 0 12px;
    /* min-height: 113px; */
    border-radius: 12px;
    position: relative;
    z-index: 10;
    margin-top: 4px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    background: linear-gradient(180deg, rgba(250, 250, 252, 0.80) 0.1%, #FAFAFC 41.16%, #FAFAFC 99.9%);
}

.signinTitle {
    margin: 0 13px;
    padding-top: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.mainTitle {
    color: #323444;
    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: relative;
}

.explain {
    color: #0EB4FF;
    font-family: "PingFang SC";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 7px;
}

.siginBtn {
    padding: 5px 12px;
    border-radius: 1000px;
    background: linear-gradient(322deg, #02D8F3 22.91%, #0DB3FF 79.17%);
    box-shadow: 0px 8px 24px 0px rgba(12, 183, 255, 0.24);
    color: #FFF;
    font-family: "PingFang SC";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    right: 10px;
}

.signinList {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
}

.signinItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: #B2B2B2;
    text-align: center;
    font-family: "PingFang SC";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 16px;
    width: 52px;
}

.signinIcon {
    width: 18px;
    height: 18px;
    margin-bottom: 8px;
}

.signinAddNum {
    color: #323444;
    font-family: Ranga;
    font-size: 12px;
    font-weight: 400;
    position: relative;
    top: -10px;
}