.countdownLayout {
    padding-top: 22px;
}

.title {
    text-align: center;
    color: #323444;
    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 27px;
}

.countdown {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
}

.point {
    color: #323444;
    font-family: "PingFang SC";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0 4px;
}

.timer {
    width: 58px;
    height: 68px;
    color: #323444;
    font-family: "PingFang SC";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: #EBEDF2;
}

.backText {
    color: #323444;
    font-family: "PingFang SC";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    align-self: flex-end;
    margin-left: 6px;
}

.taskItem {
    width: calc(100% - 24px);
    margin: 0 auto;
    height: 157px;
    border-radius: 0%;
    border-radius: 12px;
    margin-bottom: 8px;
    position: relative;
    overflow: hidden;
}

.bg {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.infoPanel {
    height: 36px;
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.taskName {
    color: #323444;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 14px;
}

.taskInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 28px;
    font-size: 12px;
}

.gift {
    margin-left: 46px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    width: 8px;
    height: 8px;
    margin-left: 2px;
}

.bottomBtnPanel {
    height: 68px;
    background-color: white;
    margin-top: 24px;
}

.bottomBtn {
    height: 44px;
    width: 342px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10000px;
    background: linear-gradient(91deg, #02D8F3 1.14%, #0DB3FF 98.43%);
    box-shadow: 0px 8px 24px 0px rgba(12, 183, 255, 0.24);
    color: #FFF;
    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    margin: 0 auto;
}