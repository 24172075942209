.layout {
    padding-top: 22px;
}

.title {
    text-align: center;
    color: #323444;
    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 27px;
}

.bottomBtnPanel {
    height: 68px;
    background-color: white;
    margin-top: 24px;
}

.bottomBtn {
    height: 44px;
    width: 342px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10000px;
    background: linear-gradient(91deg, #02D8F3 1.14%, #0DB3FF 98.43%);
    box-shadow: 0px 8px 24px 0px rgba(12, 183, 255, 0.24);
    color: #FFF;
    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    margin: 0 auto;
}

.code {
    width: 330px;
    margin: 0 auto;
    height: 68px;
    border-radius: 6px;
    background-color: #EBEDF2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #323444;
    font-family: "PingFang SC";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 300px;
}